<template>
    <div class="blogs">
        <div class="title">{{isChinese() ? '博客':'Blogs'}}</div>
        <div class="cared">
            <div class="left" v-for="(item,index) of blogsList" :key="index" @click="navTo(item,index)">
                <div class="div">
                    <img :src="item.cover" alt="">
                    <div class="view-div">
                        <div class="time">{{item.createTime.split(' ')[0]}}</div>
                        <h3>{{isChinese() ? item.title:item.titleEng}}</h3>
                        <div class="intro" >
                            {{isChinese() ? item.intro:item.introEng}}
                        </div>
                    </div>
                </div>
                <div class="tagA">{{isChinese()?'查看更多':'View more'}}</div>
            </div>
        </div>
        <div class="pagination">
            <div class="pages">
                <div class="dis">
                    <!--  -->
                    <i class="el-icon-arrow-left" @click="clickLeft" style="font-size: 30px;"
                        :style="{ color: pageNum == 1 ? '#999' : '#000' }"></i>
                    <div>
                        {{ pageNum }} / {{ Math.ceil(total / 5) }}
                    </div>
                    <i class="el-icon-arrow-right" @click="clickRigth" style="font-size: 30px;"
                        :style="{ color: pageNum > 1 ? '#000' : '#999' }"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {listBlogs} from '@/api/main.js'
export default {
    name:'blogs',
    data() {
        return {
            pagesIndex:1,
            pageNum:1,
            blogsList:[],
            total:0
        }
    },
    activated() {
        console.log(this.$router)
    },
    beforeCreate(){
        console.log(456);
    },
    beforeRouteEnter(to,from,next){
        
        console.log(from.name)
        next()
    },
    deactivated() {
       
    },
    mounted(){
        this.listBlogs();
    },
    methods: {
        listBlogs(){
            listBlogs({
                pageNum:this.pageNum,
                pageSize:6
            }).then(res=>{
                if(res.code==0){
                   this.blogsList.push(...res.data.rows);
                   this.total = res.data.total;
                }else{
                    this.showMessage();
                }
            })
        },
        navTo(item){
            this.$router.push('/details?id='+item.id)
        },
        clickLeft() {
            if (this.pageNum <= 1) return;
            this.pageNum--;
            this.listBlogs()
        },
        clickRigth() {
            if (this.pageNum >= Math.ceil(this.total / 5)) return;
            this.pageNum++;
            this.listBlogs()
        },
    }
}
</script>
<style lang="scss">
.blogs {
    width: 1200px;
    margin: auto;
    box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.08);
    .title {
        font-size: 56px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        padding: 40px 0px;
    }

    .cared {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #fff;
        flex-wrap: wrap;
        .left {
            margin-right: 28px;
            margin-bottom: 40px;
            .tagA {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                text-decoration: underline;
                padding: 20px 0px;
                cursor: pointer;
            }

            .tagA:hover {
                color: #A1A1A1;
            }
        }
        .left:nth-child(3n){
            margin-right: 0px;
        }

        .div {
            position: relative;


            img {
                width: 378px;
                height: 368px;
            }

            width: 378px;
            //height: 454px;
           // overflow: hidden;
        }

        .div:hover {
            .view-div {
                bottom: 0px;
            }
        }

        .view-div {
            width: 100%;
            background-color: #fff;
            padding: 20px 0px;
            transition: bottom .5s ease .1s;
            padding-bottom: 0px;
            .time{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                margin-bottom: 10px;
            }
            h3 {
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
            }

            .intro {
                color: #333333;
                padding: 16px 0px;
                padding-bottom: 0px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                line-height: 22px;
                height: 60px;
                overflow: hidden;
            }

            .buttom {

                width: 168px;
                padding: 8px 0px;
                background: #FFFFFF;
                border: 1px solid #333333;
                font-size: 14px;
                text-align: center;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                cursor: pointer
            }

            .buttom:hover {
                background: #000;
                color: #fff;
                transition: background 1s ease .1s, color 1s ease .1s;
            }

        }
    }
    .pagination{
        background-color: #FFFFFF;
        .pages{
            width: 1200px;
            padding: 40px 0px;
            margin: 0 auto;
            display: flex;
            justify-content: flex-end;
            .dis{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                width: 200px;
            }
        }
    }
}
</style>